<template>
    <div class="loginBox">
        <div class="header Between">
            <div class="flex-center">
                <img class="loginImg" @click="toHome" src="@/assets/img/logo.png" alt="" />
                <div class="line"></div>
                <div class="welcome_login">
                    {{ title }}
                </div>
            </div>
            <div class="header_fr flex-center" v-if="type != 1">
                已有账号？
                <div class="pleaseLogin pointer" @click="pleaseLogin">请登录></div>
            </div>
        </div>
        <div class="login_cont" v-if="type == 1">
            <div class="main flex">
                <div class="login_cont_fl">
                    <!-- <div class="product_assurance">
                        耕于建筑 · 专于产品
                    </div>
                    <div class="quality">
                        品类全 / 价格优 / 发货快 / 质量保证
                    </div>
                    <div class="code_box Between">
                        <div class="code_list">
                            <img class="code_img" src="@/assets/img/code.png" alt="">
                            <div class="scan_code">
                                扫码下载筑手建筑防护超市客户端随时随地找货下单
                            </div>
                        </div>
                        <div class="code_list">
                            <img class="code_img" src="@/assets/img/code.png" alt="">
                            <div class="scan_code">
                                扫码下载筑手建筑防护超市客户端随时随地找货下单
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="login_cont_fr">
                    <div class="tab_box Between">
                        <div class="tab_list pointer" :class="activeIndex == 1 ? 'active' : ''" @click="tabBtn(1)">
                            账号登录
                        </div>
                        <div class="line"></div>
                        <div class="tab_list pointer" :class="activeIndex == 2 ? 'active' : ''" @click="tabBtn(2)">
                            验证码登录
                        </div>
                    </div>
                    <div class="form_box">
                        <div class="form_list phone_box flex-center">
                            <div class="icon_img display">
                                <img src="@/assets/img/login/user.png" alt="" />
                            </div>
                            <input class="inp" maxlength="11" v-model="params.phone" type="text" placeholder="请输入手机号" />
                            <div v-if="mistake" class="item_error">{{ mistake }}</div>
                        </div>
                        <div class="form_list Between" v-if="activeIndex == 1">
                            <div class="flex-center">
                                <div class="icon_img display">
                                    <img src="@/assets/img/login/passWord.png" alt="" />
                                </div>
                                <input class="inp" v-model="params.password" :type="isText" placeholder="请输入密码" />
                            </div>
                            <img class="icon_show pointer" @click="changePassword"
                                :src="isText == 'password' ? icon_y : icon_n" alt="" />
                            <div v-if="isPassword" class="item_error">请输入密码</div>
                        </div>
                        <div class="Between" v-if="activeIndex == 2">
                            <div class="verification_code flex-center">
                                <input class="inp" v-model="params.code" type="text" placeholder="请输入验证码" />
                                <div v-if="isCode" class="item_error">请输入验证码</div>
                            </div>
                            <div class="sendCode">
                                <a class="sendBtn pointer display" v-show="Verification == 1"
                                    @click="getCodeBtn(1)">获取验证码</a>
                                <a class="sendBtn pointer display" v-show="Verification == 2">
                                    <span>{{ timer }}</span>秒后重新获取
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="remember_forget Between">
                        <div class="remember flex-center">
                            <input class="checkboxs" type="checkbox" />记住我
                        </div>
                        <div class="forgetPassword pointer" @click="forgetPasw">
                            忘记密码
                        </div>
                    </div>
                    <div class="loginBtn display pointer" @click="loginBtn">登录</div>
                    <div class="register_box flex" @click="toRegister">
                        <div class="newUser">新用户，</div>
                        <div class="register pointer">免费注册>></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="findBox display" v-if="type == 2 || type == 3">
            <div class="findPassword">
                <div class="stepBox" v-if="type == 2">
                    <el-steps :active="active" finish-status="success">
                        <el-step title="填写账号"></el-step>
                        <el-step title="身份验证"></el-step>
                        <el-step title="完成"></el-step>
                    </el-steps>
                </div>
                <div class="find_password_form">
                    <div class="login_cont_box" v-if="active == 0">
                        <div class="find_form_list flex-center passwordBox">
                            <div class="label flex">
                                <div class="required">*</div>
                                手机号码:
                            </div>
                            <input class="find_form_list_input" maxlength="11" v-model="formData.phone" type="text"
                                placeholder="请输入手机号" />
                            <div v-if="isPhone" class="item_error">请正确输入手机号</div>
                        </div>
                        <div class="find_form_list flex-center" v-if="type == 2">
                            <div class="label flex">
                                <div class="required">*</div>
                                验证:
                            </div>
                            <div class="VerifyBox">
                                <Verify :mode="'fixed'" :captcha-type="'blockPuzzle'"
                                    :captcha-id="'9ca07a9c-c260-50ae-2c13-89cde2f34cb9'" :container-id="'#sliderFixed_btn'"
                                    :img-size="{ width: '330px', height: '155px' }" />
                            </div>
                        </div>
                    </div>

                    <div class="login_cont_box" v-if="active == 1 || type == 3">
                        <div class="find_form_list noteBox flex-center passwordBox">
                            <div class="label flex">
                                <div class="required">*</div>
                                短信验证码:
                            </div>
                            <input class="find_form_list_input" type="text" v-model="formData.code" placeholder="请输入验证码" />
                            <div class="sendMsg_box">
                                <!-- <div class="sendMsg display">发送短信</div> -->
                                <div class="sendCode">
                                    <a class="sendMsg pointer display" v-show="Verification == 1"
                                        @click="getCodeBtn(2)">发送短信</a>
                                    <a class="sendMsg pointer display" v-show="Verification == 2">
                                        <span>{{ timer }}</span>秒后重新获取
                                    </a>
                                </div>
                            </div>
                            <div v-if="isCode" class="item_error">请输入验证码</div>
                        </div>
                        <div class="find_form_list flex-center passwordBox">
                            <div class="label flex">
                                <div class="required">*</div>
                                新密码:
                            </div>
                            <input class="find_form_list_input" v-model="formData.password" maxlength="10" type="password"
                                placeholder="请输入6-10位数，含大小写字母的密码" />
                            <div v-if="isPassword" class="item_error">{{ passwordHint }}</div>
                        </div>
                        <div class="find_form_list flex-center">
                            <div class="label flex">
                                <div class="required">*</div>
                                确认密码:
                            </div>
                            <input class="find_form_list_input" maxlength="10" v-model="formData.passwords" type="password"
                                placeholder="请输入确认密码1" />
                            <div v-if="isPasswords" class="item_error">请输入确认密码</div>
                        </div>
                    </div>

                    <div class="login_cont_box" v-if="active == 2">
                        <div class="success_box display column">
                            <div class="suces">恭喜，密码修改成功！</div>
                            <div class="hint">请牢记您新设置的密码</div>
                        </div>
                    </div>
                    <div class="nextBtn display pointer" @click="nextBtn" v-if="active != 2 && type == 2">
                        {{ active == 0 ? '下一步' : '确定' }}
                    </div>
                    <div class="nextBtn display pointer" @click="toLogin" v-if="active == 2 && type == 2">
                        立即登录
                    </div>

                    <div class="find_form_list flex-center passwordBox" v-if="type == 3">
                        <div class="label flex"></div>
                        <div class="consent flex-center pointer" @click="isShow = true">
                            <!-- <input disabled class="checkboxs" v-model="isChcked" type="checkbox"> -->
                            <img class="checkboxs" :src="isChcked == false ? checked_n : checked_y" alt="" />我已阅读并同意
                            <div class="agreement">
                                《筑手建筑防护超市网站服务协议》《筑手建筑防护超市隐私政策》
                            </div>
                        </div>
                    </div>

                    <div class="nextBtn display pointer" @click="registerBtn" v-if="type == 3">
                        立即注册
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isShow">
            <Agreement @offBtn="offBtn"></Agreement>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Footer from '@/components/footer.vue'
import Verify from './../../components/verifition/Verify'
import Agreement from './components/agreement.vue'
export default {
    components: {
        Footer,
        Verify,
        Agreement,
    },
    data() {
        return {
            title: '欢迎登录',
            activeIndex: 1,
            type: 1,
            active: 0,
            timer: 60,
            Verification: 1,
            mistake: '',
            params: {
                phone: '',
                password: '',
                code: '',
            },
            formData: {
                phone: '',
                password: '',
                passwords: '',
                code: '',
            },
            isPhone: false,
            isPassword: false,
            isPasswords: false,
            isCode: false,
            isShow: false,
            isShowPassword: false,
            isChcked: false,
            passwordHint: '',
            isText: 'password',
            icon_y: require('@/assets/img/login/isShow_y.png'),
            icon_n: require('@/assets/img/login/isShow_n.png'),
            checked_y: require('@/assets/img/login/checked_y.png'),
            checked_n: require('@/assets/img/login/checked_n.png'),
        }
    },
    mounted() {
        this.$store.commit('getUserData', {})
        localStorage.removeItem('userData')
        sessionStorage.removeItem('sidebarData')
        sessionStorage.removeItem('orderNum')

        //从首页过来的登录、注册、修改密码判断
        if (this.$route.query.type) {
            this.type = this.$route.query.type
            if (this.$route.query.type == 3) {
                this.title = '欢迎注册'
            } else if (this.$route.query.type == 2) {
                this.title = '修改密码'
                this.active = 0
            }
        }
        this.$keyBoard(this, 'loginBtn', 13)
    },
    methods: {
        //去首页
        toHome() {
            this.$router.push('/')
        },
        changePassword() {
            this.isShowPassword = !this.isShowPassword
            if (this.isShowPassword == true) {
                this.isText = 'test'
            } else {
                this.isText = 'password'
            }
        },
        //    登录
        loginBtn() {
            // phone:  17374518351,
            // password:  123123

            if (!/^[0-9]{11}$/.test(this.params.phone)) {
                return (this.mistake = '请正确输入手机号')
            } else {
                this.isPhone = false
            }
            if (this.activeIndex == 1) {
                if (this.params.password == '') {
                    return (this.isPassword = true)
                } else {
                    this.isPassword = false
                }
                this.api.loginByPwd(this.params).then((res) => {
                    if (res.code == 0) {
                        this.$store.commit('getUserData', res.data)
                        this.getCartData(res.data.token)
                        if (this.$route.query.isLogin == 'no') {
                            this.$router.go(-1)
                        } else {
                            this.$router.push('/')
                        }
                        this.mistake = ''
                    } else {
                        // this.$message.error(res.msg);
                        this.mistake = res.msg
                    }
                })
            } else {
                if (this.params.code == '') {
                    return (this.isCode = true)
                } else {
                    this.isCode = false
                }
                this.api.phoneLogin(this.params).then((res) => {
                    if (res.code == 0) {
                        this.$store.commit('getUserData', res.data)
                        this.getCartData(res.data.token)
                        if (this.$route.query.isLogin == 'no') {
                            this.$router.go(-1)
                        } else {
                            this.$router.push('/')
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        // 获取购物车商品列表
        getCartData(token) {
            let params = {
                token: token,
                shopCartGoodsParamDTOS: JSON.parse(localStorage.getItem('skuData')),
            }
            if (params.shopCartGoodsParamDTOS != null)
                this.api.addShopCartGoods(params).then((res) => {
                    localStorage.removeItem('cartData')
                    localStorage.removeItem('skuData')
                    this.$store.commit('getcartData', '')
                })
        },
        // 获取验证码
        getCodeBtn(type) {
            let phone = ''
            if (type == 1) {
                phone = this.params.phone
            } else {
                phone = this.formData.phone
            }

            if (!/^[0-9]{11}$/.test(phone)) {
                this.mistake = '请正确输入手机号'
                this.params.phone = ''
                this.formData.phone = ''
                return (this.isPhone = true)
            } else {
                this.isPhone = false
            }
            this.Verification = 2
            let auth_timer = setInterval(() => {
                //定时器设置每秒递减
                this.timer-- //递减时间
                if (this.timer <= 0) {
                    this.timer = 60
                    this.Verification = 1 //60s时间结束还原v-show状态并清除定时器
                    clearInterval(auth_timer)
                }
            }, 1000)
            this.api.sendCode({ phone: phone }).then((res) => {
                if (res.code == 0) {
                    this.$message.success('您的验证码已通过短信发送,请注意查收')
                } else {
                    this.Verification = 1
                    this.timer = 60
                    this.$message.error(res.msg)
                }
            })
        },
        //下一步
        nextBtn() {
            //  if (this.active++ > 2) this.active = 0;
            if (this.active == 0) {
                if (!/^[0-9]{11}$/.test(this.formData.phone)) {
                    this.isPhone = true
                    return (this.mistake = '请正确输入手机号')
                } else {
                    this.isPhone = false
                }
                if (this.$store.state.isVerification == false) {
                    return this.$message.error('请先验证！')
                }
                this.api.verifyPhone({ phone: this.formData.phone }).then((res) => {
                    if (res.code == 0) {
                        if (res.data.isExist == 0) {
                            this.$message.error('改手机号未注册！')
                            return
                        } else {
                            this.active = 1
                        }
                    } else {
                        return this.$message.error(res.msg)
                    }
                })
            } else if (this.active == 1) {
                if (this.formData.code == '') {
                    return (this.isCode = true)
                } else {
                    this.isCode = false
                }
                let yes = this.validPassword(this.formData.password)

                if (yes == false) {
                    this.isPassword = true
                    this.passwordHint = '请输入6-10位数，含大小写字母的密码'
                } else {
                    this.isPassword = false
                    if (this.formData.password == '') {
                        return (this.isPassword = true)
                    } else {
                        this.isPassword = false
                    }
                    if (this.formData.passwords == '') {
                        return (this.isPasswords = true)
                    } else {
                        this.isPasswords = false
                    }
                    if (this.formData.password != this.formData.passwords) {
                        return this.$message.error('两次密码不一致')
                    }
                    this.api.forgetPwd(this.formData).then((res) => {
                        this.active = 1
                        if (res.code == 0) {
                            this.active = 2
                            this.$message.success('修改成功')
                            this.formData = {}
                        }
                    })
                }
            }
        },

        tabBtn(index) {
            this.activeIndex = index
            if (this.type == 1) {
                this.title = '欢迎登录'
            } else if (this.type == 2) {
                this.title = '找回密码'
            }
        },

        success(params) {
            // params 返回的二次验证参数
        },
        toLogin() {
            this.type = 1
            this.title = '欢迎登录'
        },
        //忘记密码
        forgetPasw() {
            this.title = '找回密码'
            this.type = 2
            this.active = 0
        },
        //去注册
        toRegister() {
            this.type = 3
            this.active = 0
            this.title = '欢迎注册'
        },
        validPassword(value) {
            const num = /^.*[0-9]+.*/
            const low = /^.*[a-z]+.*/
            const up = /^.*[A-Z]+.*/
            // const spe = /^.*[^a-zA-Z0-9]+.*/
            const passLength = value.length > 6 && value.length < 10
            this.passwordHint = '请输入6-10位数，含大小写字母的密码'
            return num.test(value) && low.test(value) && up.test(value) && passLength
            // return num.test(value) && low.test(value) && up.test(value) && spe.test(value) && passLength
        },
        //点击立即注册
        registerBtn() {
            if (!/^[0-9]{11}$/.test(this.formData.phone)) {
                return (this.isPhone = true)
            } else {
                this.isPhone = false
            }
            if (this.formData.code == '') {
                return (this.isCode = true)
            } else {
                this.isCode = false
            }
            let yes = this.validPassword(this.formData.password)
            if (yes == false) {
                this.isPassword = true
            } else {
                this.isPassword = false
                if (this.formData.passwords == '') {
                    return (this.isPasswords = true)
                } else {
                    this.isPasswords = false
                }
                if (this.formData.password != this.formData.passwords) {
                    return this.$message.error('两次密码不一致')
                }
                if (this.isChcked == false) {
                    return this.$message.error('请选择我已阅读并同意')
                }
                this.api.registerApi(this.formData).then((res) => {
                    if (res.code == 0) {
                        this.$store.commit('getUserData', res.data)
                        this.$message.success('注册成功')
                        this.$router.push('/')
                        this.formData = {}
                        this.isChcked = false
                    }
                })
            }

            // if (this.formData.password == "") {
            //     this.passwordHint = "请输入密码"
            //     return this.isPassword = true
            // }else if(this.formData.password.length < 6){
            //     this.passwordHint = "密码最少设置6位数"
            //     return this.isPassword = true
            // }
            // else{
            //         this.isPassword = false
            // }
        },
        //回到登录页
        pleaseLogin() {
            this.type = 1
            this.title = '欢迎登录'
        },
        //关闭隐私协议
        offBtn(type) {
            this.isShow = false
            if (type == 2) {
                this.isChcked = true
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.loginBox {
    background: white;

    .header {
        width: 1200px;
        height: 114px;
        margin: 0 auto;
        padding-left: 6px;

        .loginImg {
            width: 300px;
            height: 86px;
        }

        .line {
            width: 1px;
            height: 51px;
            background: rgba(0, 0, 0, 0.2);
            margin-left: 35px;
            margin-right: 26px;
        }

        .welcome_login {
            font-size: 30px;
            color: #666666;
        }

        .header_fr {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;

            .pleaseLogin {
                color: #e7222a;
            }
        }
    }

    .login_cont {
        width: 100%;
        height: 580px;
        background: url('../../assets/img/login/bg.png') no-repeat;
        background-size: 100% 100%;

        .main {
            width: 1200px;
            height: 580px;
            margin: 0 auto;
            padding-left: 93px;
            padding-right: 61px;
            justify-content: space-between;

            .login_cont_fl {
                margin-top: 100px;

                .product_assurance {
                    font-size: 56px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #db2d39;
                    line-height: 58px;
                }

                .quality {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #db2d39;
                    padding-left: 39px;
                    margin-top: 21px;
                }

                .code_box {
                    width: 475px;
                    height: 257px;
                    background: rgba(0, 0, 0, 0.05);
                    border-radius: 3px;
                    margin-top: 25px;
                    padding: 33px 48px 0px 47px;
                    box-sizing: border-box;

                    .code_list {
                        .code_img {
                            width: 159px;
                            height: 159px;
                        }

                        .scan_code {
                            width: 168px;
                            height: 32px;
                            font-size: 14px;
                            color: #db2d39;
                            margin-top: 10px;
                            margin-left: -3px;
                        }
                    }
                }
            }

            .login_cont_fr {
                width: 410px;
                height: 403px;
                background: #ffffff;
                border-radius: 3px;
                margin-top: 90px;
                padding: 45px 40px 0px;
                box-sizing: border-box;

                .tab_box {
                    padding: 0px 39px;
                    margin-bottom: 42px;

                    .tab_list {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 20px;
                    }

                    .line {
                        width: 1px;
                        height: 19px;
                        background: rgba(0, 0, 0, 0.2);
                    }

                    .active {
                        color: #e93840 !important;
                    }
                }

                .form_box {
                    .phone_box {
                        margin-bottom: 30px;
                    }

                    .form_list {
                        width: 330px;
                        height: 44px;
                        border-radius: 3px;
                        border: 1px solid #cdcdcd;
                        position: relative;

                        .item_error {
                            color: #f56c6c;
                            font-size: 12px;
                            line-height: 1;
                            position: absolute;
                            left: 4px;
                            bottom: -20px;
                        }

                        .icon_img {
                            min-width: 42px;
                            height: 42px;
                            background: rgba(0, 0, 0, 0.1);

                            img {
                                width: 20px;
                                height: 22px;
                            }
                        }

                        .inp {
                            width: 100%;
                            height: 39px;
                            font-size: 14px;
                            padding-left: 10px;
                        }
                    }

                    .verification_code {
                        width: 190px;
                        height: 44px;
                        border: 1px solid #cdcdcd;
                        border-radius: 2px;
                        padding-left: 10px;
                        position: relative;

                        .inp {
                            height: 40px;
                        }

                        .item_error {
                            color: #f56c6c;
                            font-size: 12px;
                            line-height: 1;
                            position: absolute;
                            left: 4px;
                            bottom: -20px;
                        }
                    }

                    .sendBtn {
                        width: 127px;
                        height: 44px;
                        background: rgba(0, 0, 0, 0.1);
                        border-radius: 1px 1px 1px 1px;
                        font-size: 14px;
                        color: #333333;
                    }
                }

                .remember_forget {
                    margin: 25px 0;

                    .remember {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;

                        .checkboxs {
                            width: 16px;
                            height: 16px;
                            // border: 1px solid #000000;
                            border-radius: 2px;
                            margin-right: 7px;
                        }
                    }

                    .forgetPassword {
                        font-size: 12px;
                        color: #333333;
                    }
                }

                .loginBtn {
                    width: 330px;
                    height: 44px;
                    background: #db2d39;
                    border-radius: 3px;
                    font-size: 22px;
                    color: #ffffff;
                    margin-bottom: 25px;
                }

                .register_box {
                    font-size: 14px;
                    color: #666666;
                    justify-content: flex-end;

                    .newUser {}

                    .register {
                        color: #e8333f;
                    }
                }
            }
        }
    }

    .findBox {
        width: 100%;
        height: 662px;
        background: #f4f4f4;

        .findPassword {
            width: 1200px;
            height: 600px;
            background: white;
            margin: 0 auto;
            padding: 40px 0;

            .stepBox {
                width: 530px;
                margin: 0 auto;
                padding-left: 20px;
                margin-top: 30px;
            }
        }

        .find_password_form {
            padding-left: 345px;
            margin-top: 64px;

            .passwordBox {
                margin-bottom: 30px;
            }

            .sendMsg_box {
                margin-left: 12px;
            }

            .noteBox {
                .find_form_list_input {
                    width: 190px !important;
                }

                .sendMsg {
                    width: 127px;
                    height: 44px;
                    background: rgba(0, 0, 0, 0.1);
                    border-radius: 1px;
                    font-size: 14px;
                    color: #333333;
                }
            }

            .find_form_list {
                position: relative;

                .item_error {
                    color: #f56c6c;
                    font-size: 12px;
                    line-height: 1;
                    position: absolute;
                    left: 135px;
                    bottom: -20px;
                }

                .label {
                    width: 97px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    justify-content: flex-end;
                }

                .required {
                    color: #db2d39;
                    margin-right: 4px;
                }

                .find_form_list_input {
                    width: 330px;
                    height: 44px;
                    border: 1px solid #cecece;
                    border-radius: 3px;
                    margin-left: 33px;
                    font-size: 14px;
                    padding-left: 10px;
                    box-sizing: border-box;
                }

                .VerifyBox {
                    margin-left: 33px;
                }
            }

            .nextBtn {
                width: 330px;
                height: 44px;
                background: #db2d39;
                border-radius: 3px;
                font-size: 22px;
                color: #ffffff;
                margin: 0 auto;
                margin-top: 30px;
                margin-left: 130px;
            }
        }
    }

    .success_box {
        width: 180px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #7bbc52;
        margin-left: 200px;
        margin-top: 44px;
        margin-bottom: 36px;

        .suces {}

        .hint {
            color: #666666;
            font-size: 14px;
        }
    }

    .registerBox {
        margin-top: 27px !important;
    }

    .consent {
        font-size: 12px;
        color: #666666;
        margin-top: 24px;
        margin-left: 0px;

        .checkboxs {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            margin-left: 33px;
            margin-right: 12px;
        }

        .agreement {
            color: #0066cc;
        }
    }

    .icon_show {
        width: 20px;
        height: 12px;
        margin-right: 20px;
    }
}
</style>
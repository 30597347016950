<template>
    <div class="agreementBox">
        <div class="mask"></div>
        <div class="agreement_content">
            <div class="head_box Between">
                <div class="service_agreement">筑手建筑防护超市网站服务协议</div>

                                <img @click="offBtn(1)" class="off pointer" src="@/assets/img/login/off.png" alt="" />
            </div>
            <div class="contentBox">
                            <div v-html="formData.content"></div>
                <div class="btnBox display">
                                    <div class="btn display pointer" @click="offBtn(2)">同意并继续</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            formData: {},
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.api.findServer({ serviceTypeId: 53 }).then((res) => {
                this.formData = res.data
            })
        },
        offBtn(type) {
            this.$emit('offBtn', type)
        },
    },
}
</script>
<style lang="scss" scoped>
.agreementBox {
    .agreement_content {
        width: 951px;
        height: 630px;
        background: #ffffff;
        border-radius: 3px;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -315px;
        margin-left: -475px;
        z-index: 9999;

        .head_box {
            width: 100%;
            height: 38px;
            background: #f4f4f4;
            padding-left: 30px;
            padding-right: 20px;

            .service_agreement {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
            }

            .off {
                width: 20px;
                height: 20px;
            }
        }

        .contentBox {
            width: 100%;
            height: 500px;
            padding: 31px;
            padding-top: 0;
            margin-top: 31px;
            box-sizing: border-box;
            overflow: hidden;
            overflow-y: scroll;
        }

        .btnBox {
            .btn {
                width: 400px;
                height: 54px;
                background: #e62129;
                border-radius: 5px;
                font-size: 24px;
                color: #ffffff;
                margin-top: 28px;
            }
        }
    }
}
</style>